<template>
  <div>
    <profile-tabs-dashboard-merits
      :data="dataComp"
    />
  </div>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const ProfileTabsDashboardMerits = () => import('./tabDashboard/ProfileTabsDashboardMerits.vue');

export default {
  name: 'ProfileTabsDashboard',
  components: { ProfileTabsDashboardMerits },
  props: {
    data: Object,
  },
  computed: {
    dataComp() {
      // console.log('dash data', this.data);
      return this.data;
    },
    hasData() {
      return isNotEmpty(this.data);
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
